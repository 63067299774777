import { graphql, Link } from 'gatsby';
import * as React from "react"
import Layout from '~/components/Layout';
import FlexibleContent from '~/components/FlexibleContent';
import HeroHeading from '~/components/organisms/HeroHeading';
import FaqItems from '~/components/molecules/FaqItems';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import FAQsSEO from '~/components/seo/FAQsSEO';
import CustomSEO from '~/components/seo/CustomSEO';

export default function faqs({ data: { wpPage } }) {

  const page = wpPage.faqsPage.faqsPage

  const getAllFaqs = () => {

    let allFaqs = []

    page.faqs.forEach((pageFaqs) => {

      allFaqs = allFaqs.concat(pageFaqs.faqs);

    })

    return allFaqs

  }

  return (
    <Layout 
      footerResourcesType={wpPage.page.page.footerResourcesType}
      pageLanguage={wpPage.page.page.pageLanguage}
    >
      <Seo post={wpPage} />
      <CustomSEO seo={wpPage?.seo} />
      <FAQsSEO faqs={getAllFaqs()} />
      <HeroHeading {...page.heroHeading} />
      <section className="mb-20 md:mb-36">
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-3 lg:border-r border-r-navy border-opacity-10 mb-10 lg:mb-0">
              <nav className="sticky top-36">
                <ul>
                  {page.faqs.map((item, i) => (
                    <li key={i} className="mb-2 ">
                      <FadeInWhenVisible>
                        <Link href={`#${item.heading}`} className="text-navy text-opacity-30 font-medium text-xl hover:text-opacity-100 transition-colors duration-200 ease-in-out">{item.heading}</Link>
                      </FadeInWhenVisible>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="col-span-12 lg:col-span-8 lg:col-start-5">
              {page.faqs.map((faqs, i) => (
                <FaqItems key={`faqs${i}`} className="mb-20 last:mb-0" id={faqs.heading} {...faqs} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <FlexibleContent content={wpPage.page.page.content} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query faqsPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      page {
        page {
          backgroundColour
          whiteHeader
          showLogin
          footerResourcesType
          pageLanguage
          content {
            ... on WpPage_Page_Page_Content_TextCta {
              fieldGroupName
              textCta {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
      faqsPage {
        faqsPage {
          faqs {
            heading
            faqs {
              answer
              question
            }
          }
          heroHeading {
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText

              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
            illustration1 {
              ... Image
            }
            illustration2 {
              ... Image
            }
          }
        }
      }
    }
  }
`
